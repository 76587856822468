import { TLocale } from './locale';

// for now, fr-FR url's are redirecting to EN version
// since they currently weren't working too

export const privacyPolicyUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://www.agroop.net/en/privacidade-e-cookies',
  'fr-FR': 'https://www.agroop.net/en/privacidade-e-cookies',
  'es-ES': 'https://www.agroop.net/es/privacidade-e-cookies',
  'pt-PT': 'https://www.agroop.net/pt/privacidade-e-cookies',
  'pt-BR': 'https://www.agroop.net/pt/privacidade-e-cookies',
};

export const termsAndConditionsUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://www.agroop.net/en/termos-e-condicoes',
  'fr-FR': 'https://www.agroop.net/en/termos-e-condicoes',
  'es-ES': 'https://www.agroop.net/es/termos-e-condicoes ',
  'pt-PT': 'https://www.agroop.net/pt/termos-e-condicoes ',
  'pt-BR': 'https://www.agroop.net/pt/termos-e-condicoes ',
};

export const cookiesPolicyUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://www.agroop.net/en/privacidade-e-cookies',
  'fr-FR': 'https://www.agroop.net/en/privacidade-e-cookies',
  'es-ES': 'https://www.agroop.net/es/privacidade-e-cookies',
  'pt-PT': 'https://www.agroop.net/pt/privacidade-e-cookies',
  'pt-BR': 'https://www.agroop.net/pt/privacidade-e-cookies',
};

export const equipmentListUrlMap: Record<TLocale, string> = {
  'en-GB': 'https://www.agroop.net/en/equipamentos',
  'fr-FR': 'https://www.agroop.net/en/equipamentos',
  'es-ES': 'https://www.agroop.net/es/equipamentos',
  'pt-PT': 'https://www.agroop.net/pt/equipamentos',
  'pt-BR': 'https://www.agroop.net/pt/equipamentos',
};
